// import { Controller } from "@hotwired/stimulus"
import { Controller } from "stimulus"

// Connects to data-controller="today-works-map"
export default class extends Controller {
  static targets = ["map", "works"]

  connect() {

    if(window.google) {
      this.initGoogle();
    }
  }

  initGoogle() {
    const MyLatLng = { lat: 29.778915104535205, lng: -95.4562006610822 }
    const map = new google.maps.Map(this.mapTarget, {
      zoom: 10,
      center: MyLatLng,
      mapId: '6d7b88ffcf4b6e17'
    })
    this.addMarkers(map);

    new google.maps.Marker({
      position: MyLatLng,
      map,
      title: 'Office'
    })
  }

  addMarkers(map) {
    Array.from(this.worksTarget.children).forEach((address, index) => {
      console.log(index)
      var index = new google.maps.Marker({
        position: {
          lat: parseFloat(address.dataset.lat),
          lng: parseFloat(address.dataset.lng)
        },
        map,
        label: String(index + 1),
        title: address.dataset.title
      })
      const infowindow = new google.maps.InfoWindow({
          content: address.dataset.title
      })
      infowindow.open(map,index)
      // index.addListener("click", () => {
      //   infowindow.open(map, index)
      //   var li = document.querySelector(`[data-title="${address.dataset.title}"]`)
      //   console.log(li)
      //   li.classList.toggle("text-red-500")
      // })
    })
  }
}
