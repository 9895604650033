import { Controller } from "stimulus"
import intlTelInput from 'intl-tel-input';

// Connects to data-controller="phone"
export default class extends Controller {
  static targets = ["phone"]
  
  connect() {
    const input = document.querySelector("#customer_phone");
    intlTelInput(input, {
      // customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
      //   return "e.g. " + selectedCountryPlaceholder;
      // },
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
      hiddenInput: "twillio_phone_number",
      preferredCountries: ["us"]
    })
  }
}
