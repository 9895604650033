import { Controller } from "stimulus"

// Connects to data-controller="sidebar"
export default class extends Controller {
  static values  = { state: Boolean }

  connect() {
    // console.log(this.stateValue)
  }

  toggle() {
    this.stateValue = !this.stateValue

    if (this.stateValue) {
      this.openSidebar()
    } else {
      this.closeSidebar()
    }
  }
  openSidebar() {
    this.element.classList.add("active")
  }

  closeSidebar() {
    this.element.classList.remove("active")
  }
}
